
import { defineComponent, onMounted, Ref, ref } from "vue";

import Camera from "@/components/Camera.vue";

export default defineComponent({
    name: "App",
    components: {
        Camera,
    },
    setup() {
        const camera = ref<InstanceType<typeof Camera>>();

        const cameras: Ref<MediaDeviceInfo[]> = ref([]);

        onMounted(async () => {
            if (!camera.value) return;
            try {
                cameras.value = await camera.value.devices(["videoinput"]);
            } catch (e) {
                console.error(e);
            }
        });

        const start = () => camera.value?.start();
        const stop = () => camera.value?.stop();
        const pause = () => camera.value?.pause();
        const resume = () => camera.value?.resume();
        const snapshot = async () => {
            const blob = await camera.value?.snapshot({
                width: 1280,
                height: 720,
            });
            currentSnapshot.value = URL.createObjectURL(blob!);
        };

        const logEvent = (name: string) => console.log(name);

        const currentSnapshot = ref();

        const changeCamera = (event: Event) => {
            const target = event.target as HTMLSelectElement;
            camera.value?.changeCamera(target.value);
        };

        return {
            camera,
            start,
            stop,
            pause,
            resume,
            logEvent,
            snapshot,
            currentSnapshot,
            cameras,
            changeCamera,
        };
    },
});
