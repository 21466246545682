import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33098576"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "camera-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_camera = _resolveComponent("camera")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_camera, {
        ref: "camera",
        onLoading: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logEvent('loading'))),
        onStarted: _cache[1] || (_cache[1] = ($event: any) => (_ctx.logEvent('started'))),
        onError: _cache[2] || (_cache[2] = (error) => _ctx.logEvent('error: ' + error))
      }, null, 512)
    ]),
    _createElementVNode("img", { src: _ctx.currentSnapshot }, null, 8, _hoisted_2),
    _createElementVNode("div", null, [
      _createElementVNode("button", {
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.start && _ctx.start(...args)))
      }, "Start"),
      _createElementVNode("button", {
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.stop && _ctx.stop(...args)))
      }, "Stop"),
      _createElementVNode("button", {
        onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.pause && _ctx.pause(...args)))
      }, "Pause"),
      _createElementVNode("button", {
        onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.resume && _ctx.resume(...args)))
      }, "Resume"),
      _createElementVNode("button", {
        onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.snapshot && _ctx.snapshot(...args)))
      }, "Snapshot")
    ]),
    _createElementVNode("select", {
      onChange: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.changeCamera && _ctx.changeCamera(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cameras, (camera) => {
        return (_openBlock(), _createElementBlock("option", {
          key: camera,
          value: camera.deviceId
        }, _toDisplayString(camera.deviceId), 9, _hoisted_3))
      }), 128))
    ], 32)
  ], 64))
}